import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import OtherProductsHeader from "/src/components/pages/products/OtherProductsHeader";

const OneIdentityOtherProducts = ({data}) => {

    const {other} = data

    return (
        <Layout lang={other.lang} seo={other.SEO}>
            {other.product.map((item, index) => {
                return (
                    <OtherProductsHeader
                        key={index}
                        title_white={item.title_white}
                        title_color={item.title_color}
                        brand_color="one-identity-primary"
                        subtitle={item.subtitle}
                        description={item.description}
                        url={item.buttons}
                        benefits_card={item.card}
                        noAnim={index % 2 !== 0}
                        invertLotti={index === 2}
                    />
                )
            })}
        </Layout>
    )
}

export const query = graphql`
   query GetSingleOtherProducts($locale: String) {
         other: strapiOneIdentityOtherProducts(lang: { eq: $locale }) {
          lang
          product {
            title_white
            title_color
            subtitle
            description
            card {
              id
              title
            }
            buttons {
              id
              link
              name
            }
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
   }
 `

export default OneIdentityOtherProducts